<template>
  <Frame :loading="loading">
    <Grid
      :data="data"
      :columns="columns"
      :searchableColumns="searchableColumns"
      :actions="actions"
      addText="Habilitar concepto"
      @addNew="
        () => {
          manageCPS('new', null);
        }
      "
    >
    </Grid>
    <Modal :active.sync="showModal">
      <conceptPayrollSchemaManage
        @closeModal="closeModal"
        @reloadData="loadCPS"
        :payrollSchemaID="schema.PayrollSchemaID"
        :concept="concept"
        :modalMode="modalMode"
      />
    </Modal>
  </Frame>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import conceptPayrollSchemaManage from './conceptPayrollSchemaManage.vue';

export default {
  name: 'conceptPayrollSchema',
  components: {
    conceptPayrollSchemaManage,
  },
  props: {
    schema: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      showModal: false,
      modalMode: 'new',
      concept: null,
      columns: [
        {
          title: 'Tipo',
          field: 'TypeDescription',
          searchable: true,
        },
        {
          title: 'Concepto',
          field: 'ConceptDescription',
          template: this.templateConcepto,
          searchable: true,
        },
        {
          title: 'Código SAT',
          field: 'SatDescription',
          template: this.templateConceptoSat,
          searchable: true,
        },
        {
          title: 'Código de ajuste SAT',
          field: 'SatDescriptionAdjustment',
          template: this.templateConceptoSatAjuste,
          searchable: true,
        },
      ],
      searchableColumns: [
        { field: 'ConceptCode' },
        { field: 'SatConcepto' },
        { field: 'SatConceptoAdjustment' },
      ],
      actions: [
        {
          text: 'Ver',
          callback: (item) => {
            this.manageCPS('view', item);
          },
        },
        {
          text: 'Editar',
          callback: (item) => {
            this.manageCPS('edit', item);
          },
        },
        {
          text: 'Eliminar',
          callback: (item) => {
            this.actionDelete(item);
          },
        },
      ],
      loading: false,
    };
  },
  mounted() {
    this.loadCPS();
  },
  methods: {
    loadCPS() {
      this.loading = true;
      const apiClient = new ApiClient(
        `Concepts/getConceptsPayrollSchemas/${this.schema.PayrollSchemaID}`,
        'stamp'
      );
      apiClient.Get('?query=b').then((data) => {
        this.data = data.map((e) => {
          e.SatConceptoIDAdjustment = e.SatConceptoIDAdjustment ? e.SatConceptoIDAdjustment : null;
          return e;
        });
        this.loading = false;
      });
    },
    manageCPS(mode, item) {
      this.modalMode = mode;
      this.concept = item;
      this.showModal = true;
    },
    actionDelete(item) {
      this.delete('Concepts/PayrollSchema', item.ConceptPayrollSchemaID, 'stamp', () => {
        this.loadCPS();
      });
    },
    closeModal() {
      this.showModal = false;
    },
    templateConcepto(item) {
      return `${item.ConceptCode} - ${item.ConceptDescription}`;
    },
    templateConceptoSat(item) {
      return `${item.SatConcepto} - ${item.SatDescription}`;
    },
    templateConceptoSatAjuste(item) {
      if (item.SatConceptoIDAdjustment) {
        return `${item.SatConceptoAdjustment} - ${item.SatDescriptionAdjustment}`;
      }
      return '';
    },
  },
};
</script>
