<template>
  <Frame :loading="loading">
    <TabStrip :pillMode="true" v-if="!loading">
      <template v-for="(schema, index) in schemas">
        <TabStripItem :title="`${schema.Description}`" :key="index">
          <conceptPayrollSchema :schema="schema" />
        </TabStripItem>
      </template>
    </TabStrip>
  </Frame>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import conceptPayrollSchema from './conceptPayrollSchema.vue';

export default {
  name: 'schemas',
  components: {
    conceptPayrollSchema,
  },
  data() {
    return {
      schemas: [],
      loading: false,
      tabSelected: 0,
    };
  },
  mounted() {
    this.loading = true;
    const apiClient = new ApiClient('Concepts/getPayrollSchemas', 'stamp');
    apiClient.Get('?query=a').then((data) => {
      this.schemas = data;
      this.loading = false;
    });
  },
};
</script>
