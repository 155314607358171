<template>
  <Frame title="Configuración de timbrado">
    <TabStrip>
      <TabStripItem title="Conceptos">
        <conceptsGrid />
      </TabStripItem>
      <TabStripItem title="Esquemas">
        <schemas />
      </TabStripItem>
    </TabStrip>
  </Frame>
</template>

<script>
import conceptsGrid from './conceptsGrid.vue';
import schemas from './schemas.vue';

export default {
  name: 'concepts',
  components: {
    conceptsGrid,
    schemas,
  },
};
</script>
