<template>
  <Frame :loading="loading">
    <Grid
      :data="data"
      :columns="columns"
      :searchableColumns="searchableColumns"
      @addNew="
        () => {
          actionConcept('new', null);
        }
      "
      :actions="actions"
      addText="Agregar concepto"
    >
      <!-- :actions="actions"
      @reloadData="getCompanies" -->
    </Grid>
    <Modal :active.sync="showModal">
      <conceptsManage
        @closeModal="closeModal"
        @reloadData="loadConcepts"
        :concept="concept"
        :modalMode="modalMode"
      />
    </Modal>
  </Frame>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import conceptsManage from './conceptsManage.vue';

export default {
  name: 'conceptsgrid',
  components: {
    conceptsManage,
  },
  data() {
    return {
      showModal: false,
      modalMode: 'new',
      data: [],
      concept: {},
      columns: [
        {
          title: 'Concepto',
          field: 'Description',
          template: this.templateConcepto,
          searchable: true,
        },
        {
          title: 'Categoría',
          field: 'ConceptTypeDescription',
          searchable: true,
        },
        {
          title: 'Estatus',
          field: 'IsActive',
          template: this.templateActive,
        },
      ],
      searchableColumns: [{ field: 'Code' }],
      actions: [
        {
          text: 'Ver',
          callback: (item) => {
            this.actionConcept('view', item);
          },
        },
        {
          text: 'Editar',
          callback: (item) => {
            this.actionConcept('edit', item);
          },
        },
        {
          text: 'Eliminar',
          callback: (item) => {
            this.actionDelete(item);
          },
        },
      ],
      loading: false,
    };
  },
  mounted() {
    this.loadConcepts();
  },
  methods: {
    loadConcepts() {
      this.loading = true;
      const apiClient = new ApiClient('Concepts', 'stamp');
      apiClient.Get('?query=a').then((data) => {
        this.data = data;
        this.loading = false;
      });
    },
    actionConcept(mode, concept) {
      this.modalMode = mode;
      this.concept = concept;
      this.showModal = true;
    },
    actionDelete(item) {
      this.delete('Concepts', item.ConceptID, 'stamp', () => {
        this.loadConcepts();
      });
    },
    closeModal() {
      this.showModal = false;
    },
    templateActive(item) {
      const text = item.IsActive ? 'Activo' : 'Inactivo';
      const color = item.IsActive ? 'primary' : 'terciary';
      const e = `<span class="pw-tag is-${color}">${text}</span>`;
      return e;
    },
    templateConcepto(item) {
      return `${item.Code} - ${item.Description}`;
    },
  },
};
</script>
