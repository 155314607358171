<template>
  <Frame
    title="Configuración de timbrado"
    titleAction="Conceptos de nómina"
    :loading="loading"
    :mode="mode"
    :class="this.mode === 'view' ? '__no_show_required' : ''"
  >
    <Title
      v-if="this.mode !== 'view'"
      description="Indica el código, categoría y concepto de nómina que deseas agregar a tu configuración."
    />

    <Layout>
      <Column size="4">
        <Field label="Código">
          <Input
            :readonly="mode === 'view'"
            :required="computedRequired"
            textTransform="uppercase"
            v-model="model.Code"
            :rules="{ required: computedRequired, max: 10 }"
            name="Código"
          />
        </Field>
      </Column>
      <Column size="8">
        <Field label="Concepto">
          <Input
            :readonly="mode === 'view'"
            :required="computedRequired"
            textTransform="sentence"
            v-model="model.Description"
            :rules="{ required: computedRequired }"
            name="Código"
          />
        </Field>
      </Column>
      <Column size="8">
        <Field label="Cátegoria">
          <MultiSelect
            :disabled="mode === 'view'"
            :required="computedRequired"
            textTransform="uppercase"
            v-model="model.ConceptTypeID"
            :rules="{ required: computedRequired }"
            name="Cátegoria"
            :options="categories"
          />
        </Field>
      </Column>
      <Column size="4">
        <Field label="Estatus">
          <CheckBox
            :readonly="mode === 'view'"
            label="Activo"
            textTransform="uppercase"
            v-model="model.IsActive"
            name="Estatus"
          />
        </Field>
      </Column>
    </Layout>
    <hr />
    <Layout>
      <Column size="6" v-if="mode === 'view'">
        <Button color="quinary-outlined" @click="onDelete"> Eliminar </Button>
      </Column>
      <Column :size="mode === 'view' ? '6' : '12'" align="right">
        <Button color="terciary-outlined" @click="closeModal">
          {{ mode === 'view' ? 'Cerrar' : 'Cancelar' }}
        </Button>
        <Button v-if="mode === 'new'" color="secondary-outlined" @click="onSaveAndNew">
          Guardar y Nuevo
        </Button>
        <Button v-if="mode !== 'view'" color="secondary" @click="onSave"> Guardar </Button>
        <Button v-if="mode === 'view'" color="secondary-outlined" @click="changeToEdit">
          Editar
        </Button>
      </Column>
    </Layout>
  </Frame>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';

export default {
  name: 'conceptManage',
  props: {
    concept: {
      type: Object,
    },
    modalMode: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      mode: 'new',
      model: {
        ConceptID: 0,
        Code: null,
        ConceptTypeID: null,
        Description: null,
        IsActive: true,
      },
      categories: [],
    };
  },
  mounted() {
    this.mode = this.modalMode;
    if (this.modalMode !== 'new') {
      this.model = { ...this.concept };
    }
    this.loadCategories();
  },
  methods: {
    changeToEdit() {
      this.mode = 'edit';
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async onSave() {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }
      this.save('concepts', this.model, 'stamp', 'ConceptID')
        .then((data) => {
          if (data) {
            this.$emit('reloadData');
            this.closeModal();
            this.resetValidators();
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.setNotification(response.data, 'warning');
          } else {
            this.setNotification('Error inesperador, contacte con soporte.', 'warning');
          }
        });
    },
    async onDelete() {
      this.delete('Concepts', this.model.ConceptID, 'stamp', () => {
        this.$emit('reloadData');
        this.closeModal();
      });
    },
    async onSaveAndNew() {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }
      this.save('concepts', this.model, 'stamp', 'ConceptID')
        .then((data) => {
          if (data) {
            this.$emit('reloadData');
            this.$set(this, 'model', {
              ConceptID: 0,
              Code: null,
              ConceptTypeID: null,
              Description: null,
              IsActive: true,
            });
            setTimeout(() => {
              this.$nextTick(() => {
                this.resetValidators();
              });
            });
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.setNotification(response.data, 'warning');
          } else {
            this.setNotification('Error inesperador, contacte con soporte.', 'warning');
          }
        });
    },
    clean() {
      this.$set(this, 'model', {
        ConceptID: 0,
        Code: null,
        ConceptTypeID: null,
        Description: null,
        IsActive: true,
      });
      setTimeout(() => {
        this.$nextTick(() => {
          this.resetValidators();
        });
      });
    },
    loadCategories() {
      const apiClient = new ApiClient('Concepts/getConceptTypes', 'stamp');
      apiClient.Get().then((data) => {
        this.categories = data.map((e) => {
          return { id: e.ConceptTypeID, label: e.Description };
        });
        this.loading = false;
      });
    },
  },
  computed: {
    computedRequired() {
      return this.mode !== 'view';
    },
  },
};
</script>

<style lang="scss">
.__no_show_required {
  .field__label-required {
    display: none;
  }
}
</style>
