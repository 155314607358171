<template>
  <Frame
    title="Configuración de timbrado"
    titleAction="Conceptos SAT"
    :loading="loading"
    :mode="mode"
    :class="this.mode === 'view' ? '__no_show_required' : ''"
  >
    <Title
      v-if="this.mode !== 'view'"
      description="Indica los conceptos de ajuste del SAT con los que serán timbrados tus conceptos de nómina."
    />
    <Layout>
      <Column size="6">
        <Field label="Concepto">
          <MultiSelect
            :disabled="mode === 'view'"
            :required="computedRequired"
            textTransform="uppercase"
            v-model="model.ConceptID"
            :rules="{ required: computedRequired }"
            name="Concepto"
            :disable-branch-nodes="true"
            :options="Concepts"
          />
        </Field>
      </Column>
      <Column size="6">
        <Field label="Concepto SAT">
          <MultiSelect
            :disabled="mode === 'view'"
            :required="computedRequired"
            textTransform="uppercase"
            v-model="model.SatConceptoID"
            :disable-branch-nodes="true"
            :rules="{ required: computedRequired }"
            name="Concepto SAT"
            :options="SATConceptsGroup"
          />
        </Field>
      </Column>
      <Column size="6">
        <Field label="Concepto de ajuste SAT">
          <MultiSelect
            :disable-branch-nodes="true"
            :disabled="mode === 'view' || !model.SatConceptoID"
            textTransform="uppercase"
            v-model="model.SatConceptoIDAdjustment"
            name="Concepto de ajuste SAT"
            :options="SATConceptsAd"
          />
        </Field>
      </Column>
    </Layout>
    <hr />
    <Layout>
      <Column size="6" v-if="mode === 'view'">
        <Button color="quinary-outlined" @click="onDelete"> Eliminar </Button>
      </Column>
      <Column :size="mode === 'view' ? '6' : '12'" align="right">
        <Button color="terciary-outlined" @click="closeModal">
          {{ mode === 'view' ? 'Cerrar' : 'Cancelar' }}
        </Button>
        <Button v-if="mode === 'new'" color="secondary-outlined" @click="onSaveAndNew">
          Guardar y Nuevo
        </Button>
        <Button v-if="mode !== 'view'" color="secondary" @click="onSave"> Guardar </Button>
        <Button v-if="mode === 'view'" color="secondary-outlined" @click="changeToEdit">
          Editar
        </Button>
      </Column>
    </Layout>
  </Frame>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';

export default {
  name: 'conceptPayrollSchemaManage',
  props: {
    payrollSchemaID: {
      type: Number,
    },
    concept: {
      type: Object,
    },
    modalMode: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      mode: 'new',
      model: {
        ConceptPayrollSchemaID: 0,
        PayrollSchemaID: null,
        ConceptID: null,
        SatConceptoID: null,
        SatConceptoIDAdjustment: null,
      },
      Concepts: [],
      SATConcepts: [],
    };
  },
  mounted() {
    this.model.PayrollSchemaID = this.payrollSchemaID;
    this.mode = this.modalMode;
    if (this.modalMode !== 'new') {
      this.model = { ...this.concept };
    }
    this.loadConcepts();
    this.loadConceptsSAT();
  },
  methods: {
    changeToEdit() {
      this.mode = 'edit';
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async onSave() {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }
      this.save('concepts/PayrollSchema', this.model, 'stamp', 'ConceptPayrollSchemaID')
        .then((data) => {
          if (data) {
            this.$emit('reloadData');
            this.closeModal();
            this.resetValidators();
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.setNotification(response.data, 'warning');
          } else {
            this.setNotification('Error inesperador, contacte con soporte.', 'warning');
          }
        });
    },
    async onDelete() {
      this.delete('Concepts/PayrollSchema', this.model.ConceptPayrollSchemaID, 'stamp', () => {
        this.$emit('reloadData');
        this.closeModal();
      });
    },
    async onSaveAndNew() {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }
      this.save('concepts/PayrollSchema', this.model, 'stamp', 'ConceptPayrollSchemaID')
        .then((data) => {
          if (data) {
            this.$emit('reloadData');
            this.$set(this, 'model', {
              ConceptPayrollSchemaID: 0,
              PayrollSchemaID: this.payrollSchemaID,
              ConceptID: null,
              SatConceptoID: null,
              SatConceptoIDAdjustment: null,
            });
            this.loadConcepts();
            setTimeout(() => {
              this.$nextTick(() => {
                this.resetValidators();
              });
            }, 100);
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.setNotification(response.data, 'warning');
          } else {
            this.setNotification('Error inesperador, contacte con soporte.', 'warning');
          }
        });
    },
    loadConcepts() {
      this.loading = true;
      const conceptID = (this.concept || {}).ConceptID;
      const apiClient = new ApiClient(
        `Concepts/getConceptSimplifieds/${this.payrollSchemaID}${conceptID ? `/${conceptID}` : ''}`,
        'stamp'
      );
      apiClient.Get().then((data) => {
        this.Concepts = this.group(data, 'ConceptTypeID', (item) => {
          return {
            id: item.ConceptTypeCode,
            label: item.ConceptTypeDescription,
            children: data
              .filter((e) => e.ConceptTypeID === item.ConceptTypeID)
              .map((e) => {
                return {
                  id: e.ConceptID,
                  label: `${e.Code} - ${e.Description}`,
                };
              }),
          };
        });

        this.loading = false;
      });
    },
    loadConceptsSAT() {
      const apiClient = new ApiClient(`Concepts/getSatConcepts`, 'stamp');
      apiClient.Get().then((data) => {
        this.SATConcepts = data.map((e) => {
          return {
            ...e,
          };
        });
        this.loading = false;
      });
    },
    group(arr, groupby, structure) {
      const result = [];
      const map = new Map();
      // eslint-disable-next-line no-restricted-syntax
      for (const item of arr) {
        if (!map.has(item[groupby])) {
          map.set(item[groupby], true);
          result.push(structure(item));
        }
      }
      return result;
    },
  },
  computed: {
    computedRequired() {
      return this.mode !== 'view';
    },
    SATConceptsGroup() {
      return this.group(this.SATConcepts, 'TipoConceptoID', (item) => {
        return {
          id: `parent:${item.TipoConceptoID}`,
          label: item.TipoConceptoDescription,
          children: this.SATConcepts.filter((e) => e.TipoConceptoID === item.TipoConceptoID).map(
            (f) => {
              return { id: f.ConceptID, label: `${f.Code} - ${f.Description}` };
            }
          ),
        };
      });
    },
    SATConceptsAd() {
      const d = this.SATConcepts.filter((e) => e.ConceptID !== this.model.SatConceptoID);
      return this.group(d, 'TipoConceptoID', (item) => {
        return {
          id: `parent:${item.TipoConceptoID}`,
          label: item.TipoConceptoDescription,
          children: d
            .filter((e) => e.TipoConceptoID === item.TipoConceptoID)
            .map((f) => {
              return { id: f.ConceptID, label: `${f.Code} - ${f.Description}` };
            }),
        };
      });
    },
  },
  watch: {
    'model.SatConceptoID'() {
      if (this.model.SatConceptoID === this.model.SatConceptoIDAdjustment) {
        this.model.SatConceptoIDAdjustment = null;
      }
    },
  },
};
</script>

<style lang="scss">
.__no_show_required {
  .field__label-required {
    display: none;
  }
}
</style>
